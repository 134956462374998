import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import Home from "../views/Home/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/personCenter",
    name: "personCenter",
    component: () => import("@/views/PersonCenter/index.vue"),
    children: [
      {
        path: "",
        component: () =>  import("@/views/PersonCenter/PersonalData/index.vue"),
      },
      {
        path: "myCertificate",
        component: () =>  import("@/views/PersonCenter/MyCertificate/index.vue"),
      },
      {
        path: "myCourse",
        component: () =>  import("@/views/PersonCenter/MyCourse/index.vue"),
      },
      {
        path: "learningRecord",
        component: () =>  import("@/views/PersonCenter/LearningRecord/index.vue"),
      },
      {
        path: "myQuestionBank",
        component: () =>  import("@/views/PersonCenter/MyQuestionBank/index.vue"),
      },
      {
        path: "myExam",
        component:  () => import("@/views/PersonCenter/MyExam/index.vue"),
      },
      {
        path: "myCoupon",
        component: () =>  import("@/views/PersonCenter/MyCoupon/index.vue"),
      },
      {
        path: "myCertificate",
        component: () =>  import("@/views/PersonCenter/MyCertificate/index.vue"),
      },
      {
        path: "myCourse",
        component: () =>  import("@/views/PersonCenter/MyCourse/index.vue"),
      },
      {
        path: "learningRecord",
        component: () =>  import("@/views/PersonCenter/LearningRecord/index.vue"),
      },
      {
        path: "myQuestionBank",
        component: () =>  import("@/views/PersonCenter/MyQuestionBank/index.vue"),
      },
      {
        path: "myExam",
        component:  () => import("@/views/PersonCenter/MyExam/index.vue"),
      },
      {
        path: "myCoupon",
        component: () => import("@/views/PersonCenter/MyCoupon/index.vue"),
      },
    ],
  },
  {
    path: "/certificateQuery",
    name: "certificateQuery",
    component: () => import("@/views/CertificateQuery/index.vue"),
  },
  {
    path: "/demo",
    name: "demo",
    component: () => import("@/views/Demo/index.vue"),
  },
  {
    path: "/examinationDetail/:id",
    name: "examinationDetail",
    component: () => import("@/views/ExaminationDetail/index.vue"),
  },
  {
    path: "/certificateDetail/:id",
    name: "certificate",
    component: () => import("@/views/CertificateDetail/index.vue"),
  },
  {
    path: "/expertDetail/:id/:type",
    name: "expertDetail",
    component: () => import("@/views/ExpertDetail/index.vue"),
  },
  {
    path: "/curriculumDetail/:id",
    name: "curriculumDetail",
    component: () => import("@/views/CurriculumDetail/index.vue"),
  },
  {
    path: "/consultingServiceDetail/:id",
    name: "consultingServiceDetail",
    component: () => import("@/views/ConsultingServiceDetail/index.vue"),
  },
  {
    path: "/curriculum/:type",
    name: "curriculum",
    component: () => import("@/views/Curriculum/index.vue"),
  },
  {
    path: "/doingExamination/:id/:type",
    name: "doingExamination",
    component: () => import("@/views/DoingExamination/index.vue"),
  },
  {
    path: "/doneExamination/:id/:type",
    name: "doneExamination",
    component: () => import("@/views/DoneExamination/index.vue"),
  },
  {
    path: "/examination",
    name: "examination",
    component: () => import("@/views/Examination/index.vue"),
  },
  {
    path: "/consulting",
    name: "consulting",
    component: () => import("@/views/Consulting/index.vue"),
  },
  {
    path: "/moreTeacher",
    name: "moreTeacher",
    component: () => import("@/views/MoreTeacher/index.vue"),
  },
  {
    path: "/alreadySubmit",
    name: "alreadySubmit",
    component: () => import("@/views/AlreadySubmit/index.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
