// 设置 rem 函数
function setRem() {
  let htmlWidth:number = document.documentElement.clientWidth || document.body.clientWidth;
  //得到html的Dom元素
  let htmlDom:HTMLElement  = document.getElementsByTagName('html')[0];
  //设置根元素字体大小
  htmlDom.style.fontSize = htmlWidth / 20 + 'px';
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
}
