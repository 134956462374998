import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//引入全局样式
import "./globalStyle/index.css";

// 激活rem布局
import "./utils/rem";

//引入UI组件
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

//全局注册swiper
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper.min.css";

// 注册权限
import "./permission";

createApp(App)
  .component("swiper", Swiper)
  .component("swiper-slide", SwiperSlide)
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale: {
      el: {
        pagination: {
          goto: "跳转",
          pagesize: "条/页",
          total: `{total} 条`,
          pageClassifier: "页",
        },
      },
    },
  })
  .mount("#app");
