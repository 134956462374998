import http from "axios";
import { getToken, removeToken } from "@/utils/auth";
import { ElNotification } from "element-plus";
import store from "../store/index"

const axios = http.create({
  baseURL: process.env.NODE_ENV === "production" ?
    "https://gateway.zryyedu.com" : "https://gateway.zryyedu.com",
});

// request 拦截器
axios.interceptors.request.use(
  (config: any) => {
    if (getToken()) {
      config.headers["Authorization"] = "Bearer " + getToken();
    }
    return config;
  },
  (error: any) => {
    console.log("request:" + error);
    Promise.reject(error);
  }
);

// response 拦截器
axios.interceptors.response.use(
  (response: any) => {
    const data = response.data;
    if (data.code != 200) {
      ElNotification({
        title: "提示",
        type: "info",
        message: data.msg,
        position: "top-right",
        offset: 100,
      });
    }

    return data;
  },
  (error: any) => {
    switch (error.response.status) {
      case 401:
        removeToken();
        store.dispatch("updateLogin", true);
        break;
      default:
        break;
    }
    return Promise.reject(error);
  }
);

export default axios;
