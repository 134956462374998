import { pcMobileSmsCodeLogin, getInfo } from "@/api/header-list";
import { setToken } from "@/utils/auth";
import { reactive } from "vue";
export default {
  state: reactive({
    user: {},
    isLogin: false,
    isRouterView: true
  }),
  mutations: {
    UPDATE_USER(state: any, user: any) {
      state.user = user;
    },
    UPDATE_LOGIN(state: any, status: any) {
      state.isLogin = status;
    },
    Is_ROUTER_VIEW(state: any, status: any) {
      state.isRouterView = status;
    },
    REMOVE_INFO(state: any, status: any) {
      state.user = {}
    }
  },
  actions: {
    login({ commit }: any, userInfo: any) {
      const { mobile, code } = userInfo;
      return new Promise((resolve, reject) => {
        pcMobileSmsCodeLogin({
          mobile,
          code,
        })
          .then((res: any) => {
            const { data } = res;
            if (res.code == 200) {
              setToken(data.token);
              resolve(data);
            } else {
              reject(data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getInfo({ commit }: any) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((res: any) => {
            const { data } = res;
            if (res.code == 200) {
              commit("UPDATE_USER", data);
              resolve(data);
            } else {
              reject(data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    removeInfo({ commit }: any) {
      commit("REMOVE_INFO", status);
    },
    updateLogin({ commit }: any, status: any) {
      commit("UPDATE_LOGIN", status);
    },
    isRouterView({ commit }: any, status: any) {
      commit("Is_ROUTER_VIEW", status);
    }
  },
};
