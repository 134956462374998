import axios from "@/utils/axios";

export const pcSendSmsCode = () => axios.get("/captcha");
export const pcSendSmsCodeValidate = (data: {
  phone: string;
  captchaCode: string;
  uuid: string;
}) => axios.get("/api/user/validate/code/pcSendSmsCode", { params: data });

export const pcMobileSmsCodeLogin = (data: {
  mobile: string;
  code: string;
}) => axios.post("/api/user/login/pcMobileSmsCodeLogin", data);

export const getInfo = () => axios.get("/api/user/login/info");
