<template>
    <div class="adapt-box footer-box">
        <div class="footer-into-box">
            <div class="bottom-link">友情链接：国家卫生健康委员会| 国家卫生健康委人口文化发展中心 | 国家孕婴导航 | 百科名医网 | 国家孕婴网-国孕商城</div>
            <div
                class="bottom-tip"
            >国家孕婴网版权所有所有内容未经授权不得转载或做其他使用 京ICP备2021040382号-1 京公网安备11010602103999号</div>
        </div>
    </div>
</template>

<script lang="ts" setup>

</script>

<style lang="less">
.footer-box {
    display: flex;
    justify-content: center;
    .footer-into-box {
        width: 1000px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        .bottom-link {
            margin-top: 55px;
        }
        .bottom-tip {
            margin-top: 33px;
        }
    }
}
</style>